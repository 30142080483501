/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";

import PeopleIcon from "@mui/icons-material/People";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { useNavigate } from "react-router-dom";
import { Categories, SubAdmin, Trainers } from "../../components";
import useAuth from "../../hooks/useAuth";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  useLazyGetDashboardQuery,
  useLazyGetPropertiesCountQuery,
} from "../../services/main";
import { Loader, showError } from "../../constants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
type Data = {
  trainerCount: number;
  CategoryCount: number;
  subAdminCount: number;
  trainingMaterialCount: number;
};

const DashBoard = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [data, setData] = useState<Data>();
  const [getDashboardData, { isLoading }] = useLazyGetDashboardQuery();
  const [propertyCount] = useLazyGetPropertiesCountQuery();
  const [propertyData, setPropertyData] = useState<any>();

  const getDashboard = async () => {
    try {
      const response = await getDashboardData({}).unwrap();
      if (response?.statusCode === 200) {
        setData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getPropertyCount = async () => {
    try {
      const response = await propertyCount({}).unwrap();
      if (response?.statusCode === 200) {
        setPropertyData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    getPropertyCount();
  }, []);

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1>Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-trainers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Trainers</h3>
              <h4>{data?.trainerCount || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-categories")}
          >
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>Total Categories</h3>
              <h4>{data?.CategoryCount || "0"}</h4>
            </div>
          </Item>
        </Grid>

        {userData?.role === "ADMIN" ? (
          <Grid item xs={3}>
            <Item
              className="cards dashGrid_item"
              onClick={() => navigate("/manage-subAdmin")}
            >
              <PersonAddIcon className="svg_icn" />
              <div>
                <h3>Total Subadmins</h3>
                <h4>{data?.subAdminCount || "0"}</h4>
              </div>
            </Item>
          </Grid>
        ) : null}
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-categories")}
          >
            <SubtitlesIcon className="svg_icn" />
            <div>
              <h3>Total Training Materials</h3>
              <h4>{data?.trainingMaterialCount || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-categories")}
          >
            <HomeWorkIcon className="svg_icn" />
            <div>
              <h3>Total Properties</h3>
              <h4>{propertyData?.totalProperties || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-categories")}
          >
            <HomeWorkIcon className="svg_icn" />
            <div>
              <h3>Sold Properties</h3>
              <h4>{propertyData?.totalSoldProperties || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-categories")}
          >
            <HomeWorkIcon className="svg_icn" />
            <div>
              <h3>Active Properties</h3>
              <h4>{propertyData?.totalActiveProperties || "0"}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph">
        <Grid item xs={12} sx={{ mt: "20px" }}>
          <Trainers />
        </Grid>
        <Grid item xs={12}>
          <Categories />
        </Grid>
        {userData?.role === "ADMIN" ? (
          <Grid item xs={12}>
            <SubAdmin />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default DashBoard;
