/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetTrainerByIdQuery } from "../../services/main";
import { Loader, showError } from "../../constants";
import { GetTrainerById } from "../../types/General";

const TrainerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getTrainerById, { isLoading }] = useLazyGetTrainerByIdQuery();

  const [trainerData, setTrainerData] = useState<GetTrainerById>();

  const getTrainerDetail = async (id: string) => {
    try {
      const response = await getTrainerById({
        trainer_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setTrainerData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) {
      getTrainerDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Trainer Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-trainers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img
                    src={
                      trainerData?.image ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Full Name</Typography>
                      <Typography component="p">
                        {`${trainerData?.firstName || "-"} ${
                          trainerData?.lastName || "-"
                        }` || ""}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {trainerData?.email || ""}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        {" "}
                        {trainerData?.countryCode
                          ? (trainerData?.countryCode.includes("+")
                              ? ""
                              : "+") + trainerData?.countryCode
                          : null}{" "}
                        {trainerData?.phone || ""}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">Active</Typography>
                    </Box>
                  </Grid> */}
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Address 1</Typography>
                      <Typography component="p">
                        {trainerData?.address1 || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">City</Typography>
                      <Typography component="p">
                        {trainerData?.city || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Zip Code </Typography>
                      <Typography component="p">
                        {trainerData?.zipcode || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Document Name</Typography>
                      <Typography component="p">DRIVING LICENSE</Typography>
                    </Box>
                  </Grid> */}
                  {/* <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Document Validity</Typography>
                      <Typography component="p">25/12/2024</Typography>
                    </Box>
                  </Grid> */}
                  {/* <Grid xs={3} /> */}
                  {/* <Grid item xs={3}>
                    <Typography component="h5">Front Image</Typography>
                    <Box className="docs_div">
                      <figure className="doc_img">
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography component="h5">Back Image</Typography>
                    <Box className="docs_div">
                      <figure className="doc_img">
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                    </Box>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default TrainerDetails;
