/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddAgentMutation,
  useHandleResetEditPasswordMutation,
  useLazyGetAgentByIdQuery,
  useUpdateAgentMutation,
} from "../../services/main";
import { Agent } from "../../types/General";
import { useFormik } from "formik";
import { isNumber, isString } from "../../utils/validations";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";
import { error } from "console";
import { WORDPRESS_URL } from "../../constants/url";

const AddAgent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState("+966");

  const [getTrainerById, getTrainerByIdData] = useLazyGetAgentByIdQuery();
  const [addTrainer, { isLoading }] = useAddAgentMutation();
  const [updateTrainer, getUpdateTrainerData] = useUpdateAgentMutation();
  const [resetEditPassMethod] = useHandleResetEditPasswordMutation();

  const [image, setImage] = useState<string>("");
  const [role, setRole] = useState("");
  const [error, setError] = useState(false);
  const [trainerData, setTrainerData] = useState<Agent | null>(null);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [marketFilters, setMarketFilters] = useState<any>();

  const handleCenterChange = (event: SelectChangeEvent) => {
    setSelectedCenter(event.target.value as string);
  };

  const getTrainerDetail = async (id: string) => {
    try {
      const response = await getTrainerById({
        trainer_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setTrainerData(response?.data);
        setImage(response?.data?.photo || "");
        setRole(response?.data?.role || "");
        setPhoneCode(response?.data?.countryCode || "+966");
        setSelectedCenter(response?.data?.agentMarketCenter || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await resetEditPassMethod({
        id: `${id}`,
        type: "agent",
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Password has been successfully sent on the email");
        // navigate("/manage-agent");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: trainerData?.email || "",
      kw_email: trainerData?.kw_email || "",
      firstName: trainerData?.first_name || "",
      lastName: trainerData?.last_name || "",
      phone: trainerData?.phone
        ? trainerData?.phone?.replace(trainerData?.countryCode, "")
        : "",
      kw_uid: trainerData?.kw_uid || "",
      city: trainerData?.city || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      kw_email: Yup.string().matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        "Enter a valid email address"
      ),
      firstName: Yup.string()
        .required("First name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string()
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at most 16 characters"),
      kw_uid: Yup.string().min(10, "KW UID must be at least 10 digits"),
      city: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(10, "Maximum 10 character are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      setError(true);
      if (!role || !selectedCenter) {
        return;
      }
      setError(false);
      const body = {
        email: formik.values.email,
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        city: formik.values.city,
        kw_uid: formik.values.kw_uid,
        kw_email: formik.values.kw_email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: `${(phoneCode.includes("+") ? "" : "+") + phoneCode}${
          formik.values.phone
        }`,
        photo: image,
        role,
        agentMarketCenter: selectedCenter,
      } as any;

      // if (!id) {

      // }
      console.log(body, "sadsa");
      // return;

      if (id) {
        try {
          const response = await updateTrainer({
            trainer_id: `${id}`,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Agent Updated Successfully");
            navigate("/manage-agent");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addTrainer(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Agent Added Successfully");
            navigate("/manage-agent");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  useEffect(() => {
    if (id) {
      getTrainerDetail(id);
    }
  }, []);

  useEffect(() => {
    const apiUrl = `${WORDPRESS_URL}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        setMarketFilters(responseData?.data?.marketcenters);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <MainContainer>
        <Loader
          isLoad={
            isLoading ||
            getTrainerByIdData?.isLoading ||
            getUpdateTrainerData?.isLoading
          }
        />
        <div className="main_loyout">
          <div className="dashboard">
            {id ? <h1>Edit Agent</h1> : <h1>Add Agent</h1>}
            <div className="reset_btn">
              {id ? (
                <Button
                  className="btn btn_primary"
                  onClick={handleResetPassword}
                >
                  Reset password
                </Button>
              ) : undefined}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-agent");
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">First Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      disabled={trainerData?.role === "KwAgent"}
                      fullWidth
                      placeholder="FirstName"
                      id="firstName"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Last Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      disabled={trainerData?.role === "KwAgent"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder="Last Name"
                      id="lastName"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      disabled={!!id?.length}
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">KW Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="kw_email"
                      variant="outlined"
                      fullWidth
                      disabled={trainerData?.role === "KwAgent"}
                      placeholder="KW Email"
                      id="kw_email"
                      onBlur={formik.handleBlur}
                      value={formik.values.kw_email}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.kw_email && formik.errors.kw_email
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>

                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      // country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      disabled={trainerData?.role === "KwAgent"}
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">KW UID</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="kw_uid"
                      variant="outlined"
                      fullWidth
                      placeholder="KW UID"
                      disabled={trainerData?.role === "KwAgent"}
                      id="kw_uid"
                      onBlur={formik.handleBlur}
                      value={formik.values.kw_uid}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.kw_uid && formik.errors.kw_uid}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Role</Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleRoleChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem
                          value={
                            trainerData?.role === "KwAgent"
                              ? "KwAgent"
                              : "Agent"
                          }
                        >
                          Agent
                        </MenuItem>
                        <MenuItem value="Staff">Staff</MenuItem>
                      </Select>
                      {error && !role ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">City</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="city"
                      variant="outlined"
                      fullWidth
                      disabled={trainerData?.role === "KwAgent"}
                      placeholder="City"
                      id="city"
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Market Center
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCenter}
                        disabled={trainerData?.role === "KwAgent"}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCenterChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {marketFilters?.length
                          ? marketFilters?.map(
                              (item: {
                                market_center_id:
                                  | string
                                  | number
                                  | readonly string[]
                                  | undefined;
                                name: any;
                              }) => (
                                <MenuItem value={item?.market_center_id}>
                                  {item?.name || ""}
                                </MenuItem>
                              )
                            )
                          : null}
                      </Select>
                      {error && !selectedCenter ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddAgent;
