/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  Switch,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  TableContainer,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AllCategories } from "../../types/General";
import {
  useLazyDeleteCategoryByIdQuery,
  useLazyGetAllCategoriesQuery,
  useUpdateCategoryStatusMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination, WarnModal } from "../../components";
import useAuth from "../../hooks/useAuth";

const ManageCategories = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState<AllCategories[]>([]);
  const [hidePermission, setHidePermission] = useState({
    isAdd: false,
    isDelete: false,
  });
  const [getCategories, { isLoading }] = useLazyGetAllCategoriesQuery();
  const [updateCategory, getCategoryData] = useUpdateCategoryStatusMutation();
  const [deleteCategoryById, deleteCategoryByIdData] =
    useLazyDeleteCategoryByIdQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllCategories = async () => {
    try {
      const response = await getCategories({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
        setTotalCount(response?.data?.totalCategory);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateCategoryStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateCategory({
        cat_id: id,
        body: {
          isActive: !state,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        getAllCategories();
        showToast(response?.message || "Status Updated Successfully");
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategoryById({
        cat_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Category Deleted Successfully");
        const res = await getCategories({
          page: page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setCategories(res?.data?.category || []);
          setTotalCount(res?.data?.totalCategory);
        } else {
          setCategories([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    if (userData && userData.subAdminId) {
      const permission = userData.subAdminId;
      const labels = {
        trainer: "Manage Trainers",
        category: "Manage Categories",
        cms: "Manage CMS",
      };

      const data = Object.keys(labels).map((name) => {
        return {
          _id: userData.subAdminId._id,
          // @ts-ignore
          label: labels[name],
          isEdit: userData.subAdminId[`${name}Add`] || false,
          isView: userData.subAdminId[`${name}View`] || false,
          isDelete: userData.subAdminId[`${name}Delete`] || false,
          disabled: false,
          name: name,
        };
      });

      if (permission) {
        const idx = data.findIndex((ele) => ele.label === "Manage Categories");
        if (idx !== -1) {
          let newPermission = { ...hidePermission };

          if (data[idx].isEdit) {
            newPermission.isAdd = true;
          }

          if (data[idx].isDelete) {
            newPermission.isDelete = true;
          }

          setHidePermission(newPermission);
        }
      }
    }
  };

  console.log(hidePermission, "hideeeeee");

  useEffect(() => {
    checkPermission();
  }, [userData]);

  useEffect(() => {
    getAllCategories();
  }, [debouncedSearchTerm, page]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          deleteCategoryByIdData?.isLoading ||
          getCategoryData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Training Categories</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {userData?.role === "ADMIN" || hidePermission?.isAdd ? (
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-categories/add")}
                >
                  Add Category
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Category Name</TableCell>
                  <TableCell align="center">Category Name(Arabic)</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {categories?.length ? (
                categories?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell align="center">
                          <figure className="user_img">
                            <img src={item?.image || ""} alt="" />
                          </figure>
                        </TableCell>
                        <TableCell align="center">{item?.name}</TableCell>
                        <TableCell align="center">
                          {item?.name_ar || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={item?.isActive}
                            onClick={() =>
                              updateCategoryStatus(item?._id, item?.isActive)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-categories/details/${item?._id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isAdd ? (
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-categories/edit/${item?._id}`
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "white" }}
                  >
                    No Category Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={categories}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="category"
      />
    </MainContainer>
  );
};

export default ManageCategories;
