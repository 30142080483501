/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loader, showError, showToast } from "../../constants";
import { TraningMaterial } from "../../types/General";
import { Pagination, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetTrainingMaterialQuery,
  useLazyDeleteMaterialByIdQuery,
  useUpdateStatusMaterialMutation,
} from "../../services/main";
import useAuth from "../../hooks/useAuth";

const CreateMaterial = () => {
  const userData = useAuth();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [getMaterialMutation, { isLoading }] =
    useLazyGetTrainingMaterialQuery();
  const [deleteMutation] = useLazyDeleteMaterialByIdQuery();
  const [updateStatusMutation] = useUpdateStatusMaterialMutation();

  const [materialData, setMaterialData] = useState<TraningMaterial[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: false,
    isDelete: false,
  });

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteMutation({
        material_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "Delete Successfully");
        getMaterial();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, status: boolean) => {
    try {
      const response = await updateStatusMutation({
        material_id: id,
        body: {
          isActive: !status,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        getMaterial();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMaterial = async () => {
    try {
      const res = await getMaterialMutation({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setMaterialData(res?.data?.traningMaterial || []);
        setTotalCount(res?.data?.totalTraningMaterial);
      }
    } catch (error: any) {
      console.log(error, "eroro");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getMaterial();
  }, [debouncedSearchTerm, page]);

  const checkPermission = () => {
    if (userData && userData.subAdminId) {
      const permission = userData.subAdminId;
      const labels = {
        trainer: "Manage Trainers",
        category: "Manage Categories",
        cms: "Manage CMS",
        trainingMaterial: "Manage Training Material",
      };

      const data = Object.keys(labels).map((name) => {
        return {
          _id: userData.subAdminId._id,
          // @ts-ignore
          label: labels[name],
          isEdit: userData.subAdminId[`${name}Add`] || false,
          isView: userData.subAdminId[`${name}View`] || false,
          isDelete: userData.subAdminId[`${name}Delete`] || false,
          disabled: false,
          name: name,
        };
      });

      if (permission) {
        const idx = data.findIndex(
          (ele) => ele.label === "Manage Training Material"
        );
        if (idx !== -1) {
          let newPermissions = { ...hidePermission };

          if (data[idx].isEdit) {
            newPermissions.isAdd = true;
          }

          if (data[idx].isDelete) {
            newPermissions.isDelete = true;
          }

          setHidePermission(newPermissions);
        }
      }
    }
  };

  console.log(hidePermission, "hidePermission");

  useEffect(() => {
    checkPermission();
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Training Material</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {userData?.role === "ADMIN" || hidePermission?.isAdd ? (
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/training-material/add")}
                >
                  Add Material
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Content Type</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {materialData?.length ? (
                  materialData?.map((item, i) => (
                    <TableRow key={item?._id}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{item?.name || "-"}</TableCell>
                      <TableCell>{item?.description || "-"}</TableCell>
                      <TableCell>{item?.contentType || "-"}</TableCell>
                      <TableCell>{item?.categoryId?.name || "-"}</TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={item?.isActive}
                          onChange={() =>
                            updateStatus(item?._id, item?.isActive)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/training-material/view/${item?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {userData?.role === "ADMIN" ||
                          hidePermission?.isAdd ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/training-material/edit/${item?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                          {userData?.role === "ADMIN" ||
                          hidePermission?.isDelete ? (
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      No Training Material Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={materialData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="training material"
      />
    </MainContainer>
  );
};

export default CreateMaterial;
