/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useChangeTrainerStatusMutation,
  useLazyDeleteTrainerByIdQuery,
  useLazyGetAllTrainersQuery,
} from "../services/main";
import { AllTrainers } from "../types/General";
import { showError, showToast } from "../constants";
import WarnModal from "./WarnModal";
import useAuth from "../hooks/useAuth";

const Trainers = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [debouncedSearchTerm] = useState<string>("");

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: false,
    isDelete: false,
  });

  const [getTrainers] = useLazyGetAllTrainersQuery();
  const [updateTrainer] = useChangeTrainerStatusMutation();
  const [deleteTrainerById] = useLazyDeleteTrainerByIdQuery();
  const [trainers, setTrainers] = useState<AllTrainers[]>([]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const userData = useAuth();

  const getAllTrainers = async () => {
    try {
      const response = await getTrainers({
        page: 1,
        limit: 4,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setTrainers(response?.data?.trainer || []);
      } else {
        setTrainers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateTrainerStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateTrainer({
        trainer_id: id,
        body: {
          isBlocked: !state,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        getAllTrainers();
        showToast(response?.message || "Status Updated Successfully");
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTrainers();
  }, [debouncedSearchTerm]);

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteTrainerById({ trainer_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Trainer deleted successfully");
        const res = await getTrainers({
          page: 1,
          limit: 4,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setTrainers(res?.data?.trainer || []);
        } else {
          setTrainers([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    if (userData && userData.subAdminId) {
      const permission = userData.subAdminId;
      const labels = {
        trainer: "Manage Trainers",
        category: "Manage Categories",
        cms: "Manage CMS",
      };

      const data = Object.keys(labels).map((name) => {
        return {
          _id: userData.subAdminId._id,
          // @ts-ignore
          label: labels[name],
          isEdit: userData.subAdminId[`${name}Add`] || false,
          isView: userData.subAdminId[`${name}View`] || false,
          isDelete: userData.subAdminId[`${name}Delete`] || false,
          disabled: false,
          name: name,
        };
      });

      if (permission) {
        const idx = data.findIndex((ele) => ele.label === "Manage Trainers");
        if (idx !== -1) {
          let newPermissions = { ...hidePermission };

          if (data[idx].isEdit) {
            newPermissions.isAdd = true;
          }

          if (data[idx].isDelete) {
            newPermissions.isDelete = true;
          }

          setHidePermission(newPermissions);
        }
      }
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <div>
      <div className="main_loyout">
        <Card className="cards">
          <TableContainer className="table_container" sx={{ margin: 0 }}>
            <Box className="heading" sx={{ padding: 0 }}>
              <div className="dashboard">
                <h1> Trainers</h1>
              </div>
            </Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone no</TableCell>
                  <TableCell>Email</TableCell>

                  <TableCell align="center">Account Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {trainers?.length ? (
                trainers?.map((item, i) => {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center"> {i + 1}</TableCell>
                        <TableCell>
                          {`${item?.firstName} ${item?.lastName}` || ""}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {item?.countryCode
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                              item?.countryCode
                            : null}{" "}
                          {item?.phone || "-"}
                        </TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!item?.isBlocked}
                            onChange={() =>
                              updateTrainerStatus(item?._id, item?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-trainers/details/${item?._id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isAdd ? (
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-trainers/edit/${item?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(item?._id);
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "black" }}
                  >
                    No Trainers Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="trainer"
      />
    </div>
  );
};

export default Trainers;
