/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetAgentByIdQuery } from "../../services/main";
import { Loader, showError } from "../../constants";
import { Agent } from "../../types/General";
import moment from "moment";
import { WORDPRESS_URL } from "../../constants/url";

const AgentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getAgentById, { isLoading }] = useLazyGetAgentByIdQuery();

  const [trainerData, setTrainerData] = useState<Agent>();
  const [marketFilters, setMarketFilters] = useState<any>();
  const [selectedCenter, setSelectedCenter] = useState<any>();

  const getTrainerDetail = async (id: string) => {
    try {
      const response = await getAgentById({
        trainer_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setTrainerData(response?.data);
        if (response?.data?.agentMarketCenter) {
          let marketId = marketFilters.find(
            (obj: { market_center_id: string | undefined }) =>
              Number(obj.market_center_id) ===
              Number(response?.data?.agentMarketCenter)
          );
          // console.log(marketId, response?.data?.marketCenter);

          setSelectedCenter(marketId);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  // useEffect(() => {
  //   if (id) {
  //     getTrainerDetail(id);
  //   }
  // }, []);

  useEffect(() => {
    if (id && marketFilters?.length) {
      getTrainerDetail(id);
    }
  }, [marketFilters]);

  useEffect(() => {
    const apiUrl = `${WORDPRESS_URL}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        setMarketFilters(responseData?.data?.marketcenters);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Agent Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-agent");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img
                    src={
                      trainerData?.photo ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">KW UID</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.kw_uid || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">First Name</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {`${trainerData?.first_name}` || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Last Name</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {`${trainerData?.last_name || "-"}`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Role</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.role || ""}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.email || ""}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">KW Email</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.kw_email || ""}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.phone || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Address 1</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.address_1 || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">City</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.city || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Zip Code </Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.postal_code || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Created At</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {trainerData?.createdAt
                          ? moment(trainerData?.createdAt).format(
                              "DD MMMM YYYY"
                            )
                          : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Market Center</Typography>
                      <Typography component="p">
                        {selectedCenter?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AgentDetail;
