import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ForgotPasswordPage from "./auth/forgotPassword";
import ChangePassword from "./auth/changePassword";
import VerifyOtp from "./auth/otp";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import ManageTrainers from "./manageTrainers";
import AddTrainer from "./manageTrainers/add";
import TrainerDetails from "./manageTrainers/details";
import ViewTrainingMaterial from "./manageCategories/details";
import AddTrainingMaterial from "./createTrainingMaterial/add";
import ViewAdminTrainingMaterial from "./createTrainingMaterial/view";
import CreateMaterial from "./createTrainingMaterial";
import AddAgent from "./manageAgent/add";
import ManageAgent from "./manageAgent";
import AgentDetail from "./manageAgent/details";
import ViewTraining from "./createTraining/view";
import AddTrainingLocation from "./createTraining/add";
import ManageTraingLocation from "./createTraining";
import ManageListing from "./manageListing";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ForgotPasswordPage,
  VerifyOtp,
  ResetPassword,
  Profile,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  ManageTrainers,
  AddTrainer,
  TrainerDetails,
  ViewTrainingMaterial,
  ChangePassword,
  AddTrainingMaterial,
  ViewAdminTrainingMaterial,
  CreateMaterial,
  AddAgent,
  ManageAgent,
  AgentDetail,
  ViewTraining,
  AddTrainingLocation,
  ManageTraingLocation,
  ManageListing,
};
