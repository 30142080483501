/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Loader, showError, showToast } from "../../constants";
import {
  useHandleAppSettingMutation,
  useLazyGetAppSettingApiQuery,
} from "../../services/main";

const ManageListing = () => {
  const navigate = useNavigate();

  const [openListing, setOpenListing] = useState(false);
  const [exclusiveListing, setExclusiveListing] = useState(true);
  const [allListing, setAllListing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [handleAppMethod] = useHandleAppSettingMutation();
  const [getAppMethod] = useLazyGetAppSettingApiQuery();

  const handleChangeStatus = async () => {
    let body = {
      Exclusive: exclusiveListing || allListing ? true : false,
      Open: openListing || allListing ? true : false,
    };
    try {
      setLoading(true);
      const response = await handleAppMethod(body).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        getAppMethodData();
        showToast("Status Updated Successfully");
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const getAppMethodData = async () => {
    try {
      setLoading(true);
      const response = await getAppMethod({}).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        if (
          response?.data?.Exclusive === true &&
          response?.data?.Open === true
        ) {
          setAllListing(true);
          setOpenListing(true);
          setExclusiveListing(true);
        }
        if (response?.data?.Open === true) {
          setOpenListing(true);
        }
        if (response?.data?.Exclusive === true) {
          setExclusiveListing(true);
        }
        // showToast("Status Updated Successfully");
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAppMethodData();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Listing</h1>
        </div>
        <Card className="cards">
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="center">Open Listing</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={openListing}
                      onChange={() => {
                        setOpenListing(!openListing);
                        // handleChangeStatus(1);
                      }}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Exclusive Listing</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={exclusiveListing}
                      onChange={() => {
                        setExclusiveListing(!exclusiveListing);
                        // handleChangeStatus(2);
                      }}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">All Listing</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={allListing}
                      onChange={() => {
                        setAllListing(!allListing);

                        // handleChangeStatus(3);
                      }}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="form_btn">
            <Button
              size="large"
              type="submit"
              className="btn btn_primary"
              onClick={handleChangeStatus}
            >
              Save
            </Button>
          </div>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageListing;
