/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetAllSubadminResponse } from "../types/General";
import {
  useLazyDeleteSubadminByIdQuery,
  useLazyGetSubadminQuery,
  useUpdateSubadminStatusMutation,
} from "../services/main";
import { showError, showToast } from "../constants";
import WarnModal from "./WarnModal";

const SubAdmin = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const [debouncedSearchTerm] = useState<string>("");

  const [subadminList, setSubadminList] = useState<GetAllSubadminResponse[]>(
    []
  );
  const [getSubadminList] = useLazyGetSubadminQuery();
  const [updateSubadmin] = useUpdateSubadminStatusMutation();
  const [deleteSubadminById] = useLazyDeleteSubadminByIdQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getSubadmins = async () => {
    try {
      const response = await getSubadminList({
        page: 1,
        limit: 4,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubadminList(response?.data?.subAdmin || []);
      } else {
        setSubadminList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateSubadmin({
        sub_id: id,
        body: {
          isBlocked: !state,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        getSubadmins();
        showToast(response?.message || "Status Updated Successfully");
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubadminById({ sub_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Subadmin deleted successfully");
        getSubadmins();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getSubadmins();
  }, [debouncedSearchTerm]);

  return (
    <div>
      <div className="main_loyout">
        <Card className="cards">
          <TableContainer className="table_container" sx={{ margin: 0 }}>
            <Box className="heading" sx={{ padding: 0 }}>
              <div className="dashboard">
                <h1> Sub-Admins</h1>
              </div>
            </Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Full name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {subadminList?.length ? (
                subadminList?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center"> {i + 1}</TableCell>

                        <TableCell>
                          {`${item?.firstName || "-"} ${
                            item?.lastName || ""
                          } ` || "-"}
                        </TableCell>
                        <TableCell>{item?.email || ""}</TableCell>
                        <TableCell>
                          {" "}
                          {item?.countryCode
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                              item?.countryCode
                            : null}{" "}
                          {item?.phone || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            onChange={() =>
                              updateStatus(item?._id, item?.isBlocked)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-subAdmin/edit/${item?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={10}>
                    No Subadmin Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="subadmin"
      />
    </div>
  );
};

export default SubAdmin;
