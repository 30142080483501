/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { Loader, showError } from "../../constants";
import { TraningMaterial } from "../../types/General";
import { useLazyGetMaterialByIdQuery } from "../../services/main";

const ViewAdminTrainingMaterial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getMaterialById, { isLoading }] = useLazyGetMaterialByIdQuery();

  const [material, setMaterial] = useState<TraningMaterial | null>(null);

  const handleGetMaterialById = async (id: string) => {
    try {
      const response = await getMaterialById({ material_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setMaterial(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      handleGetMaterialById(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/training-material");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Title</Typography>
                      <Typography component="p">
                        {material?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Title(Arabic)</Typography>
                      <Typography component="p">
                        {material?.title_ar || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">
                        {material?.description || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">
                        Description(Arabic)
                      </Typography>
                      <Typography component="p">
                        {material?.description_ar || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Number of Hours</Typography>
                      <Typography component="p">
                        {material?.noOfHours || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Number of Sessions</Typography>
                      <Typography component="p">
                        {material?.noOfSession || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Video Link</Typography>
                      {material?.googleDriveLink ? (
                        <a
                          href={material?.googleDriveLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography component="p">
                            {material?.googleDriveLink || "-"}
                          </Typography>
                        </a>
                      ) : (
                        "-"
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Doc Link</Typography>
                      {material?.docLink ? (
                        <a
                          href={material?.docLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography component="p">
                            {material?.docLink || "-"}
                          </Typography>
                        </a>
                      ) : (
                        "-"
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Content Type</Typography>
                      <Typography component="p">
                        {material?.contentType || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography component="h5">Category</Typography>
                      <Typography component="p">
                        {material?.categoryId?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ViewAdminTrainingMaterial;
