/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetCategoryByIdQuery } from "../../services/main";
import { GetCategoryResponse } from "../../types/General";
import { Loader, showError } from "../../constants";

const ViewTrainingMaterial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getCategotyById, { isLoading }] = useLazyGetCategoryByIdQuery();

  const [categoryData, setCategoryData] = useState<
    GetCategoryResponse | undefined
  >();
  const getCategotyDetail = async (id: string) => {
    try {
      const response = await getCategotyById({
        cat_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getCategotyDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Training Material</h1>
        </div>
        <Card className="cards">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ margin: 0 }}>{categoryData?.name || ""}</h2>
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/manage-categories")}
            >
              Back
            </Button>
          </Box>

          <TableContainer className="table_container" sx={{ margin: 0 }}>
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Added By</TableCell>
                  <TableCell>Video Link</TableCell>
                  <TableCell>Doc Link</TableCell>
                  <TableCell>Content Type</TableCell>
                </TableRow>
              </TableHead>
              {categoryData && categoryData?.traningmaterials?.length ? (
                categoryData?.traningmaterials?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{item?.name || ""}</TableCell>
                        <TableCell>{item?.description || ""}</TableCell>
                        {item?.trainerId ? (
                          <TableCell>
                            {`${item?.trainer?.firstName || ""} ${
                              item?.trainer?.lastName || ""
                            }`}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {`${item?.admin?.firstName || ""} ${
                              item?.admin?.lastName || ""
                            }`}
                          </TableCell>
                        )}

                        <TableCell>
                          <a
                            href={item?.googleDriveLink || ""}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.googleDriveLink || ""}
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            href={item?.docLink || ""}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.docLink || ""}
                          </a>
                        </TableCell>
                        <TableCell>{item?.contentType || "-"}</TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "black" }}
                  >
                    No Training Material Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ViewTrainingMaterial;
