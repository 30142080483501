/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import useAuth from "../hooks/useAuth";
import { AllCategories } from "../types/General";
import {
  useLazyDeleteCategoryByIdQuery,
  useLazyGetAllCategoriesQuery,
  useUpdateCategoryStatusMutation,
} from "../services/main";
import { showError, showToast } from "../constants";
import WarnModal from "./WarnModal";

const Categories = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [debouncedSearchTerm] = useState<string>("");
  const [categories, setCategories] = useState<AllCategories[]>([]);
  const [hidePermission, setHidePermission] = useState({
    isAdd: false,
    isDelete: false,
  });
  const [getCategories] = useLazyGetAllCategoriesQuery();
  const [updateCategory] = useUpdateCategoryStatusMutation();
  const [deleteCategoryById] = useLazyDeleteCategoryByIdQuery();

  const getAllCategories = async () => {
    try {
      const response = await getCategories({
        page: 1,
        limit: 4,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateCategoryStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateCategory({
        cat_id: id,
        body: {
          isActive: !state,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        getAllCategories();
        showToast(response?.message || "Status Updated Successfully");
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategoryById({
        cat_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Category Deleted Successfully");
        const res = await getCategories({
          page: 1,
          limit: 4,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setCategories(res?.data?.category || []);
        } else {
          setCategories([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    if (userData && userData.subAdminId) {
      const permission = userData.subAdminId;
      const labels = {
        trainer: "Manage Trainers",
        category: "Manage Categories",
        cms: "Manage CMS",
      };

      const data = Object.keys(labels).map((name) => {
        return {
          _id: userData.subAdminId._id,
          // @ts-ignore
          label: labels[name],
          isEdit: userData.subAdminId[`${name}Add`] || false,
          isView: userData.subAdminId[`${name}View`] || false,
          isDelete: userData.subAdminId[`${name}Delete`] || false,
          disabled: false,
          name: name,
        };
      });

      if (permission) {
        const idx = data.findIndex((ele) => ele.label === "Manage Categories");
        if (idx !== -1) {
          let newPermission = { ...hidePermission };

          if (data[idx].isEdit) {
            newPermission.isAdd = true;
          }

          if (data[idx].isDelete) {
            newPermission.isDelete = true;
          }

          setHidePermission(newPermission);
        }
      }
    }
  };

  console.log(hidePermission, "hideeeeee");

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    getAllCategories();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <div className="main_loyout">
        <Card className="cards">
          <TableContainer className="table_container " sx={{ margin: 0 }}>
            <Box className="heading" sx={{ padding: 0 }}>
              <div className="dashboard">
                <h1> Categories</h1>
              </div>
            </Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {categories?.length ? (
                categories?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell align="center">
                          <figure className="user_img">
                            <img src={item?.image || ""} alt="" />
                          </figure>
                        </TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={item?.isActive}
                            onClick={() =>
                              updateCategoryStatus(item?._id, item?.isActive)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-categories/details/${item?._id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isAdd ? (
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-categories/edit/${item?._id}`
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "white" }}
                  >
                    No Category Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="category"
      />
    </div>
  );
};

export default Categories;
