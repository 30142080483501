/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useLazyDeleteSubadminByIdQuery,
  useLazyGetSubadminQuery,
  useUpdateSubadminStatusMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination, WarnModal } from "../../components";
import { GetAllSubadminResponse } from "../../types/General";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [subadminList, setSubadminList] = useState<GetAllSubadminResponse[]>(
    []
  );
  const [getSubadminList, { isLoading }] = useLazyGetSubadminQuery();
  const [updateSubadmin, subadminStatusData] =
    useUpdateSubadminStatusMutation();
  const [deleteSubadminById, deleteSubadminByIdData] =
    useLazyDeleteSubadminByIdQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getSubadmins = async () => {
    try {
      const response = await getSubadminList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubadminList(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.totalSubAdmin);
      } else {
        setSubadminList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateSubadmin({
        sub_id: id,
        body: {
          isBlocked: !state,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        getSubadmins();
        showToast(response?.message || "Status Updated Successfully");
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubadminById({ sub_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Subadmin deleted successfully");
        getSubadmins();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getSubadmins();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading ||
          deleteSubadminByIdData?.isLoading ||
          subadminStatusData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                Add Sub-Admin
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Full name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {subadminList?.length ? (
                subadminList?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>
                          {`${item?.firstName || "-"} ${
                            item?.lastName || ""
                          } ` || "-"}
                        </TableCell>
                        <TableCell>{item?.email || ""}</TableCell>
                        <TableCell>
                          {" "}
                          {item?.countryCode
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                              item?.countryCode
                            : null}{" "}
                          {item?.phone || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            onChange={() =>
                              updateStatus(item?._id, item?.isBlocked)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-subAdmin/edit/${item?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    // sx={{ color: "white" }}
                  >
                    No Subadmin Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={subadminList}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="subadmin"
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
