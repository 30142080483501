/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import * as Yup from "yup";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import { isString, useFormik } from "formik";
import {
  useLazyGetCategoryByIdQuery,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import AddIcon from "@mui/icons-material/Add";
import { GetCategoryResponse } from "../../types/General";
import { isNumber } from "../../utils/validations";

const AddCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [image, setImage] = useState<string>("");
  const [addCategory, { isLoading }] = usePostCategoryMutation();
  const [error, setError] = useState<boolean>(false);
  const [getCategoryById, getCategoryByIdData] = useLazyGetCategoryByIdQuery();
  const [updateCategory, getUpdateCategoryData] = useUpdateCategoryMutation();
  const [categoryData, setCategoryData] = useState<GetCategoryResponse>();

  const getCategoryDetail = async (id: string) => {
    try {
      const response = await getCategoryById({
        cat_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data);
        setImage(response?.data?.image || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: categoryData?.name || "",
      nameAr: categoryData?.name_ar || "",
      sessionCount: categoryData?.noOfSession || "",
      hoursCount: categoryData?.noOfHours || "",

      // nameAr: categoryData?.name_ar || ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      nameAr: Yup.string()
        // .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      sessionCount: Yup.string(),
      // .required("This field is required")
      hoursCount: Yup.string(),
      // .required("This field is required")
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        name: formik.values.name,
        image: image,
        name_ar: formik.values.nameAr,
        noOfHours: formik.values.hoursCount,
        noOfSession: formik.values.sessionCount,
        // nameAr:  formik.values.name_ar
      };
      if (!image) {
        setError(true);
        return;
      }
      if (id) {
        try {
          console.log("edit");
          const response = await updateCategory({
            body,
            cat_id: `${id}`,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "Category Updated Successfully");
            navigate("/manage-categories");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      } else {
        try {
          console.log("add");
          const response = await addCategory(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "Category Added Successfully");
            navigate("/manage-categories");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };
  useEffect(() => {
    if (id) {
      getCategoryDetail(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader
          isLoad={
            isLoading ||
            getUpdateCategoryData?.isLoading ||
            getCategoryByIdData?.isLoading
          }
        />
        <div className="main_loyout">
          <div className="dashboard">
            {id ? <h1>Edit Category</h1> : <h1>Add Category</h1>}
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Category Name
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      id="name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Category Name(Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="nameAr"
                      variant="outlined"
                      fullWidth
                      placeholder="Arabic Name"
                      id="nameAr"
                      onBlur={formik.handleBlur}
                      value={formik.values.nameAr}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.nameAr && formik.errors.nameAr}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Number of Sessions
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="sessionCount"
                      variant="outlined"
                      fullWidth
                      placeholder="Number of Sessions"
                      id="sessionCount"
                      onBlur={formik.handleBlur}
                      value={formik.values.sessionCount}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.sessionCount &&
                        formik.errors.sessionCount
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Number of Hours
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="hoursCount"
                      variant="outlined"
                      fullWidth
                      placeholder="  Number of Hours"
                      id="hoursCount"
                      onBlur={formik.handleBlur}
                      value={formik.values.hoursCount}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.hoursCount && formik.errors.hoursCount
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
