/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";
import { useFormik } from "formik";
import { isString } from "../../utils/validations";
import {
  useAddSubadminMutation,
  useHandleResetEditPasswordMutation,
  useLazyGetSubadminByIdQuery,
  useUpdateSubadminMutation,
} from "../../services/main";

interface DataType {
  _id?: string;
  label: string;
  isEdit: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
  name: string;
}

const AddSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState("+966");
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState<string>("");
  const [subAdminData, setSubadminData] = useState<any>();
  const [permissions, setPermissions] = useState<DataType[]>([
    // {
    //   _id: "1",
    //   label: "Dashboard",
    //   isEdit: false,
    //   isView: false,
    //   isDelete: false,
    //   disabled: false,
    //   name: ""
    // },

    {
      _id: "2",
      label: "Manage Trainers",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
      name: "trainer",
    },
    {
      _id: "3",
      label: "Manage Categories",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
      name: "category",
    },
    {
      _id: "4",
      label: "Manage CMS",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
      name: "cms",
    },
    {
      _id: "5",
      label: "Manage Training Material",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
      name: "trainingMaterial",
    },
    {
      _id: "6",
      label: "Manage Agents",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
      name: "agents",
    },
  ]);

  const [getSubAdminMethod, { isLoading }] = useLazyGetSubadminByIdQuery();
  const [addSubAdminMethod] = useAddSubadminMutation();
  const [updateSubAdminMethod] = useUpdateSubadminMutation();

  const [resetEditPassMethod] = useHandleResetEditPasswordMutation();

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isEdit = !key.isEdit;
      if (key.isEdit) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    }
    setPermissions([...permissions]);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: subAdminData?.firstName || "",
      lastName: subAdminData?.lastName || "",
      email: subAdminData?.email || "",
      phone: subAdminData?.phone || "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      lastName: Yup.string()

        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      password: id
        ? Yup.string().notRequired()
        : Yup.string()
            .required("Password is required!")
            .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      // if (!image) {
      //   setError(true);
      //   return;
      // }

      const result = {
        subAdminId: {},
      };

      permissions?.length > 0 &&
        permissions?.forEach((item) => {
          const { name, isEdit, isView, isDelete } = item;
          result.subAdminId[`${name}Add`] = isEdit || false;
          result.subAdminId[`${name}View`] = isView || false;
          result.subAdminId[`${name}Delete`] = isDelete || false;
        });

      console.log({ result });

      const subAdminId = result?.subAdminId || {};
      let body = {
        ...{
          email: formik.values.email,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          phone: formik.values.phone,
          image: image,
          password: formik.values.password,
        },
        ...subAdminId,
      };
      console.log(body, "body");

      const update_body = {
        ...{
          email: formik.values.email,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          phone: formik.values.phone,
          image: image,
        },
        ...subAdminId,
      };

      if (id) {
        try {
          const response = await updateSubAdminMethod({
            sub_id: id,
            body: update_body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subadmin Updated Successfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubAdminMethod(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subadmin Created Successfully");
            navigate("/manage-subAdmin");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getSubAdminDetail = async () => {
    try {
      const res = await getSubAdminMethod({ sub_id: id }).unwrap();
      if (res?.statusCode === 200) {
        setSubadminData(res?.data);
        setPhoneCode(res?.data?.countryCode || "+966");
        setImage(res?.data?.image || "");
        const labels = {
          trainer: "Manage Trainers",
          category: "Manage Categories",
          cms: "Manage CMS",
          trainingMaterial: "Manage Training Material",
          agent: "Manage Agents",
        };

        const data = Object.keys(labels).map((name) => {
          return {
            _id: res?.data?.subAdminId._id,
            label: labels[name],
            isEdit: res?.data?.subAdminId[`${name}Add`] || false,
            isView: res?.data?.subAdminId[`${name}View`] || false,
            isDelete: res?.data?.subAdminId[`${name}Delete`] || false,
            disabled: false,
            name: name,
          };
        });

        console.log(data, "dataaaa");
        setPermissions(data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await resetEditPassMethod({
        id: `${id}`,
        type: "subadmin",
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Password has been successfully sent on the email");
        // navigate("/manage-agent");
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getSubAdminDetail();
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            {id ? <h1>Edit Sub-Admin</h1> : <h1>Add Sub-Admin</h1>}
            <div className="reset_btn">
              {id ? (
                <Button
                  className="btn btn_primary"
                  onClick={handleResetPassword}
                >
                  Reset password
                </Button>
              ) : undefined}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-subAdmin");
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">First Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      placeholder="First Name"
                      id="firstName"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Last Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder="Last Name"
                      id="lastName"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      placeholder="Phone Number"
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {id ? null : (
                    <Grid item xs={4}>
                      <Typography className="custom_label">Password</Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder="Password"
                        fullWidth
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TableContainer className="table_container">
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Module</TableCell>
                            <TableCell>Add/Edit</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions.map((row, i) => (
                            <TableRow key={row?._id}>
                              <TableCell>{row.label}</TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() =>
                                    onSelectChange(row, "addEdit")
                                  }
                                  checked={row?.isEdit || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isview")}
                                  checked={row?.isView || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isdel")}
                                  checked={row?.isDelete || false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    // onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
