/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import {
  useAddTrainingMutation,
  useEditTrainingMutation,
  useLazyGetTrainingByIdQuery,
} from "../../services/event";
import { Autocomplete } from "@react-google-maps/api";
import moment from "moment";
import { Training } from "../../types/General";
import { isString } from "../../utils/validations";
import { WORDPRESS_URL } from "../../constants/url";

const AddTrainingLocation = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [addTraining, { isLoading }] = useAddTrainingMutation();
  const [trainingById, trainingByIdData] = useLazyGetTrainingByIdQuery();
  const [editTraining, editTrainingData] = useEditTrainingMutation();
  const [selectedCenter, setSelectedCenter] = useState("");
  const [marketFilters, setMarketFilters] = useState<any>();

  const [autocomplete, setAutocomplete] = useState(null);
  const [training, setTraining] = useState<Training | null>(null);
  const [error, setError] = useState(false);

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const handleCenterChange = (event: SelectChangeEvent) => {
    setSelectedCenter(event.target.value as string);
  };

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      let address = place.address_components;
      let city = "",
        zip = "";
      address.forEach(function (component: any) {
        let types = component.types;
        if (types.indexOf("locality") > -1) {
          city = component.long_name;
        }
        if (types.indexOf("postal_code") > -1) {
          zip = component.long_name;
        }
        var lat = place.geometry.location.lat();
        // get lng
        var lng = place.geometry.location.lng();

        formik.setFieldValue("location", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("lat", lat || "");
        formik.setFieldValue("long", lng || "");
        // if (types.indexOf("sublocality_level_1") > -1) {
        //   addLine2 = component?.long_name;
        // }

        // if (types.indexOf("administrative_area_level_1") > -1) {
        //   state = component.long_name;
        // }
      });

      // get lat

      // var latLong = {
      //   lat: lat,
      //   lng: lng,
      // };
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: training?.title || "",
      description: training?.description || "",
      dateTime: training?.date
        ? moment(training?.date).format("YYYY-MM-DDTHH:mm")
        : "",
      location: training?.address1 || "",
      zipCode: training?.postalCode || "",
      city: training?.city || "",
      lat: training?.latitude || "",
      long: training?.longitude || "",
      titleAr: training?.title_ar || "",
      descriptionAr: training?.description_ar || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      titleAr: Yup.string()
        // .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      descriptionAr: Yup.string()
        // .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      location: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      dateTime: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!selectedCenter) {
        return;
      }

      const body = {
        title: formik.values.title,
        description: formik.values.description,
        description_ar: formik.values.descriptionAr,
        title_ar: formik.values.titleAr,
        date: moment(formik.values.dateTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        latitude: formik.values.lat,
        longitude: formik.values.long,
        address1: formik.values.location,
        postalCode: formik.values.zipCode,
        city: formik.values.city,
        marketCenter: selectedCenter,
        marketCenterName: marketFilters?.length
          ? marketFilters.find(
              (obj: { market_center_id: string | undefined }) =>
                Number(obj.market_center_id) === Number(selectedCenter)
            )?.name
          : "",
      };

      if (id) {
        try {
          const response = await editTraining({
            body,
            training_id: id,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-training");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addTraining(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-training");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const handleTrainingById = async (id: string) => {
    try {
      const response = await trainingById({ training_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setTraining(response?.data);
        if (response?.data?.marketCenter) {
          setSelectedCenter(response?.data?.marketCenter);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && marketFilters?.length) {
      handleTrainingById(id);
    }
  }, [marketFilters]);

  useEffect(() => {
    const apiUrl = `${WORDPRESS_URL}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        setMarketFilters(responseData?.data?.marketcenters);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <MainContainer>
        <Loader
          isLoad={
            editTrainingData?.isLoading ||
            isLoading ||
            trainingByIdData?.isLoading
          }
        />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Event" : "Add Event"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-training");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Title</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      id="title"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Title(Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="titleAr"
                      variant="outlined"
                      fullWidth
                      placeholder="Title(Arabic)"
                      id="titleAr"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.titleAr}
                      helperText={
                        formik.touched.titleAr && formik.errors.titleAr
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Description(Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description(Arabic)"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="descriptionAr"
                      variant="outlined"
                      id="descriptionAr"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.descriptionAr}
                      helperText={
                        formik.touched.descriptionAr &&
                        formik.errors.descriptionAr
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Date & Time
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"datetime-local"}
                      name="dateTime"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      id="dateTime"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.dateTime}
                      helperText={
                        formik.touched.dateTime && formik.errors.dateTime
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Location</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        fullWidth
                        placeholder="Location"
                        name="location"
                        variant="outlined"
                        id="location"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                        helperText={
                          formik.touched.location && formik.errors.location
                        }
                        onKeyPress={onKeypress}
                      ></TextField>
                    </Autocomplete>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="custom_label">City</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="City"
                      name="city"
                      id="city"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      helperText={formik.touched.city && formik.errors.city}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Zipcode</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Zipcode"
                      name="zipCode"
                      id="zipCode"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipCode}
                      helperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Market Center
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCenter}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCenterChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {marketFilters?.length
                          ? marketFilters?.map(
                              (item: {
                                market_center_id:
                                  | string
                                  | number
                                  | readonly string[]
                                  | undefined;
                                name: any;
                              }) => (
                                <MenuItem value={item?.market_center_id}>
                                  {item?.name || ""}
                                </MenuItem>
                              )
                            )
                          : null}
                      </Select>
                      {error && !selectedCenter ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    {id ? "Update" : "Save"}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddTrainingLocation;
