import { END_POINTS } from "../constants/url";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type PostLoginBody = {
  key: string;
  password: string;
};

type PostForgotPasswordBody = {
  key: string;
};

type PostVerifyOtpBody = {
  key: string;
  code: string;
};
type PostVerifyOtpResponse = {
  statusCode: number;
  message: string;
  data: any;
};
type PostSetPasswordBody = {
  password: string;
};
type PostSetPasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};
type PostChangePasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};
type PostChangePasswordBody = {
  oldPassword: string;
  password: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      PostLoginBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    postForgotPassword: builder.mutation<
      CommonResponseType,
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),
    postVerifyOtp: builder.mutation<PostVerifyOtpResponse, PostVerifyOtpBody>({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),
    postResendOtp: builder.mutation<CommonResponseType, PostForgotPasswordBody>(
      {
        query: (body) => ({
          url: END_POINTS.resendOtp,
          method: "POST",
          body,
        }),
      }
    ),
    postSetPassword: builder.mutation<
      PostSetPasswordResponse,
      PostSetPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      PostChangePasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    getUser: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.get_user,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation<
      { statusCode: number; data: User; message: string },
      Partial<Omit<User, "image">>
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgotPasswordMutation,
  useLazyGetUserQuery,
  useEditProfileMutation,
  usePostVerifyOtpMutation,
  usePostResendOtpMutation,
  usePostSetPasswordMutation,
  usePostChangePasswordMutation,
} = authApi;
