/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleIcon from "@mui/icons-material/People";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { SIDEBAR_WIDTH } from "../constants";
import useAuth from "../hooks/useAuth";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useAuth();
  const items = [
    // {
    //   id: 1,
    //   name: "dashboard",
    //   icon: <DashboardIcon />,
    //   route: "/dashboard",
    // },
    {
      id: 7,
      name: "Manage Agents",
      icon: <GroupAddIcon />,
      route: "/manage-agent",
    },
    {
      id: 2,
      name: "Manage Trainers",
      icon: <PeopleIcon />,
      route: "/manage-trainers",
    },
    {
      id: 8,
      name: "Manage Events",
      icon: <ListAltIcon />,
      route: "/manage-training",
    },
    {
      id: 3,
      name: "Manage Categories",
      icon: <ListAltIcon />,
      route: "/manage-categories",
    },
    {
      id: 4,
      name: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      route: "/cms",
    },
    {
      id: 5,
      name: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      route: "/manage-subAdmin",
    },
    {
      id: 6,
      name: "Manage Training Material",
      icon: <NoteAddIcon />,
      route: "/training-material",
    },
    {
      id: 8,
      name: "Manage Listing",
      icon: <ListAltIcon />,
      route: "/manage-listing",
    },
  ];

  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : []
  );

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/logo.png"
            alt=""
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            navigate("/dashboard");
          }}
        >
          <>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText className="lstitm-txt" primary="Dashboard" />
          </>
        </ListItemButton>
        {list?.length
          ? list?.map((item: any) => (
              <ListItemButton
                key={item?.id}
                className="lst-itm"
                selected={location.pathname.includes(item?.route)}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  item?.id !== 4 && item?.route
                    ? navigate(item?.route)
                    : window.open(
                        "https://cms.kwsaudiarabia.com/wp-json/custom/v1/loginadmin",
                        "_blank"
                      );
                }}
              >
                {item?.icon ? (
                  <>
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText className="lstitm-txt" primary={item?.name} />
                  </>
                ) : undefined}
              </ListItemButton>
            ))
          : undefined}
      </List>
    </>
  );

  useEffect(() => {
    if (userData?.role === "ADMIN") {
      setList(items);
    } else {
      let updatedItems = [...items];

      if (userData && userData.subAdminId) {
        const labels = {
          trainer: "Manage Trainers",
          category: "Manage Categories",
          cms: "Manage CMS",
          trainingMaterial: "Manage Training Material",
          agent: "Manage Agents",
        };

        const data = Object.keys(labels).map((name) => {
          return {
            _id: userData.subAdminId._id,
            // @ts-ignore
            label: labels[name],
            isEdit: userData.subAdminId[`${name}Add`] || false,
            isView: userData.subAdminId[`${name}View`] || false,
            isDelete: userData.subAdminId[`${name}Delete`] || false,
            disabled: false,
            name: name,
          };
        });

        updatedItems = updatedItems.filter((item) => {
          const dataItem = data.find(
            (dataItem) => dataItem.label === item.name
          );

          if (dataItem && dataItem.isView) {
            return true;
          }

          return false;
        });
      }

      setList(updatedItems);
    }
  }, [userData]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
