import { END_POINTS } from "../constants/url";
import {
  Agent,
  AllCategories,
  AllTrainers,
  GetAllSubadminResponse,
  GetCategoryResponse,
  GetTrainerById,
  TraningMaterial,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllTrainersResponse = {
  totalTrainer: number;
  trainer: AllTrainers[];
};

type GetAllAgentResponse = {
  totalagents: number;
  agents: Agent[];
};

type GetUsersParams = {
  trainer_id: string;
  page?: number;
  query?: string;
  limit?: number;
};

type GetTokenParams = {
  limit: number;
  page: number;
  query: string;
};

type PostCategoryBody = {
  image: string;
  name: string;
};
type PostTrainerBody = {
  image: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
};
// type GetCategoryResponse = {
//   image: string;
//   name: string;
//   categoryType: number;
//   description: string;
//   isDeleted: boolean;
//   _id: string;
//   traningmaterials: {
//     categoryId: string;
//     contentType: string;
//     createdAt: string;
//     googleDriveLink: string;
//     docLink: string;
//     description: string;
//     isActive: true;
//     isDeleted: true;
//     name: string;
//     _id: string;
//     trainerId: string;
//     trainer: {
//       firstName: string;
//       lastName: string;
//     };
//     admin: {
//       firstName: string;
//       lastName: string;
//     };
//   }[];
// };
type GetAllCategoriesResponse = {
  totalCategory: number;
  category: AllCategories[];
};
type GetSubadminResponse = {
  totalSubAdmin: number;
  subAdmin: GetAllSubadminResponse[];
};
type DashboardData = {
  trainerCount: number;
  CategoryCount: number;
  subAdminCount: number;
  trainingMaterialCount: number;
};

type GetMaterialList = {
  totalTraningMaterial: number;
  traningMaterial: TraningMaterial[];
};
type materialBody = {
  name: string;
  googleDriveLink: string;
  categoryId: string;
  contentType: string;
  docLink: string;
  description: string;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: DashboardData },
      {}
    >({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),

    getPropertiesCount: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.propertiesCount,
        method: "GET",
      }),
    }),

    getAllTrainers: builder.query<
      CommonResponseType & { data: GetAllTrainersResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getAllTrainers +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    addTrainer: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      PostTrainerBody
    >({
      query: (body) => ({
        url: `${END_POINTS.addTrainer}`,
        method: "POST",
        body,
      }),
    }),
    updateTrainer: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      { body: PostTrainerBody; trainer_id: string }
    >({
      query: ({ body, trainer_id }) => ({
        url: `${END_POINTS.updateTrainer}/${trainer_id}`,
        method: "POST",
        body,
      }),
    }),

    changeTrainerStatus: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      { body: { isBlocked: boolean }; trainer_id: string }
    >({
      query: ({ trainer_id, body }) => ({
        url: `${END_POINTS.updateTrainer}/${trainer_id}/`,
        method: "POST",
        body,
      }),
    }),
    getTrainerById: builder.query<
      CommonResponseType & { data: GetTrainerById },
      GetUsersParams
    >({
      query: ({ trainer_id }) => ({
        url: `${END_POINTS.getTrainerById}/${trainer_id}`,
        method: "GET",
      }),
    }),
    deleteTrainerById: builder.query<
      CommonResponseType & { data: GetAllTrainersResponse },
      { trainer_id: string }
    >({
      query: ({ trainer_id }) => ({
        url: `${END_POINTS.deleteTrainer}/${trainer_id}`,
        method: "DELETE",
      }),
    }),

    //agent

    getAllAgent: builder.query<
      CommonResponseType & { data: GetAllAgentResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getAllAgent +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    addAgent: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      Pick<
        Agent,
        "first_name" | "last_name" | "phone" | "countryCode" | "email"
      >
    >({
      query: (body) => ({
        url: `${END_POINTS.addAgent}`,
        method: "POST",
        body,
      }),
    }),
    updateAgent: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      {
        body: Pick<
          Agent,
          "first_name" | "last_name" | "phone" | "countryCode" | "email"
        >;
        trainer_id: string;
      }
    >({
      query: ({ body, trainer_id }) => ({
        url: `${END_POINTS.updateAgent}/${trainer_id}`,
        method: "POST",
        body,
      }),
    }),

    changeAgentStatus: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      { body: { isBlocked: boolean }; trainer_id: string }
    >({
      query: ({ trainer_id, body }) => ({
        url: `${END_POINTS.updateAgent}/${trainer_id}/`,
        method: "POST",
        body,
      }),
    }),
    getAgentById: builder.query<
      CommonResponseType & { data: Agent },
      GetUsersParams
    >({
      query: ({ trainer_id }) => ({
        url: `${END_POINTS.getAgentById}/${trainer_id}`,
        method: "GET",
      }),
    }),
    deleteAgentById: builder.query<
      CommonResponseType & { data: GetAllTrainersResponse },
      { trainer_id: string }
    >({
      query: ({ trainer_id }) => ({
        url: `${END_POINTS.deleteAgent}/${trainer_id}`,
        method: "DELETE",
      }),
    }),
    //agent

    postCategory: builder.mutation<
      CommonResponseType & { data: GetCategoryResponse },
      PostCategoryBody
    >({
      query: (body) => ({
        url: `${END_POINTS.addCategory}`,
        method: "POST",
        body,
      }),
    }),
    getAllCategories: builder.query<
      CommonResponseType & { data: GetAllCategoriesResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getCategory +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getCategoryById: builder.query<
      CommonResponseType & { data: GetCategoryResponse },
      { cat_id: string }
    >({
      query: ({ cat_id }) => ({
        url: `${END_POINTS.getCategoryById}/${cat_id}`,
        method: "GET",
      }),
    }),
    updateCategory: builder.mutation<
      CommonResponseType & { data: GetCategoryResponse },
      { body: PostCategoryBody; cat_id: string }
    >({
      query: ({ body, cat_id }) => ({
        url: `${END_POINTS.editCategory}/${cat_id}`,
        method: "POST",
        body,
      }),
    }),
    updateCategoryStatus: builder.mutation<
      CommonResponseType & { data: GetCategoryResponse },
      { body: { isActive: boolean }; cat_id: string }
    >({
      query: ({ body, cat_id }) => ({
        url: `${END_POINTS.editCategory}/${cat_id}`,
        method: "POST",
        body,
      }),
    }),
    deleteCategoryById: builder.query<
      CommonResponseType & { data: GetAllCategoriesResponse },
      { cat_id: string }
    >({
      query: ({ cat_id }) => ({
        url: `${END_POINTS.deleteCategory}/${cat_id}`,
        method: "DELETE",
      }),
    }),
    addSubadmin: builder.mutation<
      CommonResponseType & { data: GetAllTrainersResponse },
      PostTrainerBody
    >({
      query: (body) => ({
        url: END_POINTS.subadmin,
        method: "POST",
        body,
      }),
    }),
    getSubadmin: builder.query<
      CommonResponseType & { data: GetSubadminResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.subadmin +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getSubadminById: builder.query<
      CommonResponseType & { data: GetSubadminResponse },
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.subadmin}/${sub_id}`,
        method: "GET",
      }),
    }),
    updateSubadmin: builder.mutation<
      CommonResponseType & { data: GetSubadminResponse },
      { body: PostTrainerBody; sub_id: string }
    >({
      query: ({ body, sub_id }) => ({
        url: `${END_POINTS.subadmin}/${sub_id}`,
        method: "PUT",
        body,
      }),
    }),
    updateSubadminStatus: builder.mutation<
      CommonResponseType & { data: GetSubadminResponse },
      { body: { isBlocked: boolean }; sub_id: string }
    >({
      query: ({ body, sub_id }) => ({
        url: `${END_POINTS.subadmin}/${sub_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteSubadminById: builder.query<
      CommonResponseType & { data: GetSubadminResponse },
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.subadmin}/${sub_id}`,
        method: "DELETE",
      }),
    }),
    //training material
    getTrainingMaterial: builder.query<
      CommonResponseType & { data: GetMaterialList },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getTraningMaterial +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getMaterialById: builder.query<
      CommonResponseType & { data: TraningMaterial },
      { material_id: string }
    >({
      query: ({ material_id }) => ({
        url: `${END_POINTS.getTraningMaterial}/${material_id}`,
        method: "GET",
      }),
    }),
    addTrainingMaterial: builder.mutation<
      CommonResponseType & { data: GetMaterialList },
      materialBody
    >({
      query: (body) => ({
        url: END_POINTS.addTraningMaterial,
        method: "POST",
        body,
      }),
    }),
    editTrainingMaterial: builder.mutation<
      CommonResponseType & { data: GetMaterialList },
      { material_id: string; body: materialBody }
    >({
      query: ({ body, material_id }) => ({
        url: `${END_POINTS.editTraningMaterial}/${material_id}`,
        method: "POST",
        body,
      }),
    }),
    updateStatusMaterial: builder.mutation<
      CommonResponseType & { data: GetMaterialList },
      {
        material_id: string;
        body: { isActive: boolean };
      }
    >({
      query: ({ body, material_id }) => ({
        url: `${END_POINTS.editTraningMaterial}/${material_id}`,
        method: "POST",
        body,
      }),
    }),
    deleteMaterialById: builder.query<
      CommonResponseType,
      { material_id: string }
    >({
      query: ({ material_id }) => ({
        url: `${END_POINTS.deleteTraningMaterial}/${material_id}`,
        method: "DELETE",
      }),
    }),

    handleResetEditPassword: builder.mutation<
      CommonResponseType,
      {
        id: string;
        type: string;
      }
    >({
      query: ({ type, id }) => ({
        url: `${END_POINTS.resetEditPass}/${id}?type=${type}`,
        method: "POST",
        // body,
      }),
    }),

    handleAppSetting: builder.mutation<
      CommonResponseType,
      {
        Exclusive: boolean;
        Open: boolean;
      }
    >({
      query: (body) => ({
        url: `${END_POINTS.addAppSetting}`,
        method: "POST",
        body,
      }),
    }),

    getAppSettingApi: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.getAppSetting}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetDashboardQuery,
  useLazyGetAllTrainersQuery,
  useLazyGetTrainerByIdQuery,
  usePostCategoryMutation,
  useLazyGetAllCategoriesQuery,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useLazyDeleteCategoryByIdQuery,
  useAddTrainerMutation,
  useUpdateTrainerMutation,
  useChangeTrainerStatusMutation,
  useLazyDeleteTrainerByIdQuery,
  useUpdateCategoryStatusMutation,
  useAddSubadminMutation,
  useLazyGetSubadminQuery,
  useLazyGetSubadminByIdQuery,
  useLazyDeleteSubadminByIdQuery,
  useUpdateSubadminMutation,
  useUpdateSubadminStatusMutation,
  useLazyGetPropertiesCountQuery,
  //training materiall
  useLazyGetTrainingMaterialQuery,
  useAddTrainingMaterialMutation,
  useLazyGetMaterialByIdQuery,
  useEditTrainingMaterialMutation,
  useUpdateStatusMaterialMutation,
  useLazyDeleteMaterialByIdQuery,

  //agent
  useLazyGetAllAgentQuery,
  useAddAgentMutation,
  useChangeAgentStatusMutation,
  useLazyGetAgentByIdQuery,
  useLazyDeleteAgentByIdQuery,
  useUpdateAgentMutation,
  useHandleResetEditPasswordMutation,
  useHandleAppSettingMutation,
  useLazyGetAppSettingApiQuery,
} = mainApi;
