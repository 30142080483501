import { Routes, Route, useNavigate } from "react-router-dom";
import Pages from "./pages";
import { useAppDispatch } from "./hooks/store";
import { useEffect } from "react";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />

      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />

      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route
        path="/manage-categories/details/:id"
        element={<Pages.ViewTrainingMaterial />}
      />

      <Route path="/manage-trainers" element={<Pages.ManageTrainers />} />
      <Route path="/manage-trainers/add" element={<Pages.AddTrainer />} />
      <Route path="/manage-trainers/edit/:id" element={<Pages.AddTrainer />} />
      <Route
        path="/manage-trainers/details/:id"
        element={<Pages.TrainerDetails />}
      />

      <Route path="/manage-agent" element={<Pages.ManageAgent />} />
      <Route path="/manage-agent/add" element={<Pages.AddAgent />} />
      <Route path="/manage-agent/edit/:id" element={<Pages.AddAgent />} />
      <Route path="/manage-agent/details/:id" element={<Pages.AgentDetail />} />

      <Route path="/training-material" element={<Pages.CreateMaterial />} />
      <Route
        path="/training-material/add"
        element={<Pages.AddTrainingMaterial />}
      />
      <Route
        path="/training-material/edit/:id"
        element={<Pages.AddTrainingMaterial />}
      />
      <Route
        path="/training-material/view/:id"
        element={<Pages.ViewAdminTrainingMaterial />}
      />
      <Route
        path="/manage-training/view/:id"
        element={<Pages.ViewTraining />}
      />
      <Route
        path="/manage-training/edit/:id"
        element={<Pages.AddTrainingLocation />}
      />
      <Route
        path="/manage-training/add"
        element={<Pages.AddTrainingLocation />}
      />
      <Route path="/manage-training" element={<Pages.ManageTraingLocation />} />
      <Route path="/manage-listing" element={<Pages.ManageListing />} />
    </Routes>
  );
};

export default Routing;
