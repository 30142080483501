import { END_POINTS } from "../constants/url";
import { Training } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type trainingBody = {
  title: string;
  description: string;
  date: string;
  latitude: string | number;
  longitude: string | number;
};

type GetTrainingList = {
  totalTraning: number;
  traning: Training[];
};

type GetTokenParams = {
  limit: number;
  page: number;
  query: string;
};
export const eventApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTraining: builder.query<
      CommonResponseType & { data: GetTrainingList },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        // url: `${END_POINTS.getTraning}`,
        url:
          END_POINTS.getTraning +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getTrainingById: builder.query<
      CommonResponseType & { data: Training },
      { training_id: string }
    >({
      query: ({ training_id }) => ({
        url: `${END_POINTS.getTraning}/${training_id}`,
        method: "GET",
      }),
    }),

    addTraining: builder.mutation<
      CommonResponseType & { data: GetTrainingList },
      trainingBody
    >({
      query: (body) => ({
        url: END_POINTS.addTraning,
        method: "POST",
        body,
      }),
    }),
    deleteTrainingById: builder.query<
      CommonResponseType & { data: GetTrainingList },
      { training_id: string }
    >({
      query: ({ training_id }) => ({
        url: `${END_POINTS.deleteTraning}/${training_id}`,
        method: "DELETE",
      }),
    }),
    editTraining: builder.mutation<
      CommonResponseType & { data: GetTrainingList },
      { training_id: string; body: trainingBody }
    >({
      query: ({ body, training_id }) => ({
        url: `${END_POINTS.editTraning}/${training_id}`,
        method: "POST",
        body,
      }),
    }),
    updateStatusTraining: builder.mutation<
      CommonResponseType & { data: GetTrainingList },
      {
        training_id: string;
        body: { isActive: boolean };
      }
    >({
      query: ({ body, training_id }) => ({
        url: `${END_POINTS.editTraning}/${training_id}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAddTrainingMutation,
  useLazyGetTrainingQuery,
  useLazyGetTrainingByIdQuery,
  useLazyDeleteTrainingByIdQuery,
  useEditTrainingMutation,
  useUpdateStatusTrainingMutation,
} = eventApi;
